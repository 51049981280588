<template>
    <div>
        <v-dialog v-model="settingsDialog" width="600">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="settingsDialog = true">
                    <v-icon dark>
                        mdi-account-cog
                    </v-icon>
                </v-btn>
            </template>
            <user-settings-form @close="settingsDialog = false" />
        </v-dialog>

        <v-dialog v-model="logoutDialog" width="350">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    icon
                    v-on="on"
                    @click="logoutDialog = true">
                    <v-icon dark>
                        mdi-logout
                    </v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-spacer />
                    <v-toolbar-title>Log Out</v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <v-card-text class="my-2 pt-5">
                    Are you sure you would like to log out?
                </v-card-text>
                <v-divider />
                <v-card-actions class="mx-1 py-4">
                    <v-spacer />
                    <app-button :outlined="true" @click="logOutCanceled">
                        No
                    </app-button>
                    <app-button @click="logout">
                        Yes
                    </app-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    components: {
        userSettingsForm: () => import("@/features/account/views/UserSettingsForm")
    },
    data() {
        return {
            logoutDialog: false,
            settingsDialog: false,
        };
    },

    methods: {
        ...mapActions({
            logout: "authentication/logout"
        }),
        logOutCanceled() {
            this.logoutDialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/theme.scss";
</style>
